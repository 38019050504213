<template>
  <div>
    <el-form
      ref="form"
      size="medium"
      :model="loginForm"
      :rules="rules"
      class="login-content__box__form"
    >
      <el-form-item prop="username" v-if="!isSignIn">
        <el-input
          v-model="loginForm.username"
          maxlength="64"
          clearable
          placeholder="User Name"
        ></el-input>
      </el-form-item>
      <el-form-item prop="email">
        <el-input v-model.trim="loginForm.email" clearable placeholder="Email Address"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model.trim="loginForm.password"
          :type="isView ? 'text' : 'password'"
          placeholder="Password"
          @blur="isShowRuleError = true"
        >
          <img slot="suffix" :src="isView ? visibility : visibilityOff" @click="isView = !isView" />
        </el-input>
      </el-form-item>

      <div class="rules-error">
        <Transition name="el-zoom-in-top">
          <span v-show="!isSignIn && isShowRuleError && loginForm.password.length < 8"
            >Enter at least 8 characters</span
          >
        </Transition>

        <Transition name="el-zoom-in-top">
          <span v-show="!isSignIn && isShowRuleError && !/[a-zA-Z]/.test(loginForm.password)">
            Contain at least 1 English character
          </span>
        </Transition>
      </div>
    </el-form>
    <div class="login-content__box__operate _flex _flex-justify__between" v-show="isSignIn">
      <div class="login-content__box__remember">
        <el-checkbox v-model="isRemember">Remember me</el-checkbox>
      </div>
      <div
        class="primary-color _font-size__sub__heading _font-weight__600 _pointer"
        @click="$emit('switchComponent', 'ForgetPwd')"
      >
        Forget Password?
      </div>
    </div>

    <el-button
      type="primary"
      class="login-content__box__sign-in"
      :loading="loading"
      @click="handleSubmit"
    >
      {{ isSignIn ? 'Sign In' : 'Sign up' }}
    </el-button>

    <div
      v-if="isTip && !isSignIn"
      class="login-content__forget__send-success _font-weight__400 _font-size__sub__heading"
    >
      Verify your email.
    </div>

    <div class="_font-size__sub__heading login-content__box__sign-up">
      <!-- <expand-dom :render="renderSign"></expand-dom> -->
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import visibilityOff from '@/assets/icons/login/visibility-off.png'
import visibility from '@/assets/icons/login/visibility.png'
import { checkMailRegistered, sendMailRegister, AuthRegister } from '@/api/auth'
import { debounce } from '@/utils/debounce-throttle'
import { Decrypt, Encrypt } from '@/utils/ASE-secret'
import { TYPE_ENUM, renderSign, sendToEmail, emailRules, urlToObj } from './index'

export default {
  components: {
    // expandDom: {
    //   functional: true,
    //   props: {
    //     render: Function
    //   },
    //   render: (h, ctx) => {
    //     return ctx.props.render(h, ctx)
    //   }
    // }
  },
  data() {
    return {
      visibilityOff,
      visibility,
      renderSign: renderSign.bind(this),
      loading: false,
      isTip: false,
      isShowRuleError: false,
      rules: {
        username: { required: true, message: 'Please enter username', trigger: ['change', 'blur'] },
        email: [
          ...emailRules,
          {
            validator: (rule, value, callback) => {
              if (!this.isSignIn) {
                debounce(() => {
                  checkMailRegistered(value).then((res) => {
                    if (res.result)
                      callback('ID already exists, you can try logging in with this ID.')
                    else callback()
                  })
                })
              } else {
                callback()
              }
            }
          }
        ],
        password: {
          trigger: ['change', 'blur'],
          validator: (rule, value, callback) => {
            if (this.isSignIn && value !== '') {
              callback()
            } else if (value === '' || value.length < 8 || !/[a-zA-Z]/.test(value)) {
              callback('Please enter a valid password')
            } else {
              callback()
            }
          }
        }
      },
      isView: false,
      isRemember: false,

      // 是否登录
      isSignIn: true,

      loginForm: {
        username: '',
        email: '',
        password: ''
      }
    }
  },
  mounted() {
    // 读取本地缓存
    this.isRemember = localStorage.getItem('isRemember') === 'true'
    if (this.isRemember) {
      this.loginForm.email = Decrypt(Cookies.get('email'))
      this.loginForm.password = Decrypt(Cookies.get('password'))
    }

    const { code, params } = this.$route.query
    if (!code || !params) return

    const paramData = urlToObj(params)
    // 判断 type 是否注册
    if (paramData.type && paramData.type === TYPE_ENUM.SIGN_UP) {
      AuthRegister({
        code,
        loginName: paramData.email,
        loginPwd: paramData.password,
        nickName: paramData.username
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('Registered success!')
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$message.error('fail to register')
        })
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          // 登录逻辑
          if (this.isSignIn) {
            this.$store
              .dispatch('businessLogin', {
                username: this.loginForm.email,
                password: this.loginForm.password
              })
              .then(() => {
                localStorage.setItem('isRemember', this.isRemember)
                // 是否记住我
                if (this.isRemember) {
                  Cookies.set('email', Encrypt(this.loginForm.email))
                  Cookies.set('password', Encrypt(this.loginForm.password))
                } else {
                  Cookies.remove('email')
                  Cookies.remove('password')
                }
                this.loading = false
                this.$router.replace({ path: '/' })
              })
              .catch(() => {
                this.loading = false
              })
          } else {
            // 注册逻辑
            const url = sendToEmail(this.loginForm)

            sendMailRegister(this.loginForm.email, url)
              .then((res) => {
                if (res.code === 200) {
                  this.isTip = true
                } else {
                  this.$message.error(res.message)
                }
                this.loading = false
              })
              .catch(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
